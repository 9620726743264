/**
 * @license
 * MyFonts Webfont Build ID 3660179, 2018-10-24T15:15:58-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirLTPro-Book by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-45-book/
 * 
 * Webfont: AvenirLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-65-medium/
 * 
 * Webfont: AvenirLTPro-Heavy by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-85-heavy/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3660179
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/37d993");*/
@font-face {font-family: 'AvenirLTPro-Book';src: url('/webfonts/37D993_0_0.eot');src: url('/webfonts/37D993_0_0.eot?#iefix') format('embedded-opentype'),url('/webfonts/37D993_0_0.woff2') format('woff2'),url('/webfonts/37D993_0_0.woff') format('woff'),url('/webfonts/37D993_0_0.ttf') format('truetype');}
@font-face {font-family: 'AvenirLTPro-Medium';src: url('/webfonts/37D993_1_0.eot');src: url('/webfonts/37D993_1_0.eot?#iefix') format('embedded-opentype'),url('/webfonts/37D993_1_0.woff2') format('woff2'),url('/webfonts/37D993_1_0.woff') format('woff'),url('/webfonts/37D993_1_0.ttf') format('truetype');}
@font-face {font-family: 'AvenirLTPro-Heavy';src: url('/webfonts/37D993_2_0.eot');src: url('/webfonts/37D993_2_0.eot?#iefix') format('embedded-opentype'),url('/webfonts/37D993_2_0.woff2') format('woff2'),url('/webfonts/37D993_2_0.woff') format('woff'),url('/webfonts/37D993_2_0.ttf') format('truetype');}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
